<template>
    <div
        class="stepper"
        :class="{ 'stepper--disabled': disabled }"
        :id="id"
        :min="min"
        :max="max"
    >
        <button
            class="stepper__button stepper__button--minus"
            :class="{ 'stepper__button--disabled': disabledMinus }"
            @click="decrement"
            type="button"
            data-cy="decrement"
            name="button"
        >
            <IconsMinus />
        </button>
        <input
            :id="idPrefix + id"
            class="equal-base equal-base--tighter"
            type="number"
            pattern="\d*"
            :min="min"
            :max="max"
            v-model="internalValue"
            @input="validate(internalValue)"
            @blur="blur"
            @keydown="validateInput"
            :disabled="disabled"
            data-cy="quantityInput"
        />
        <button
            class="stepper__button stepper__button--plus"
            :class="{ 'stepper__button--disabled': disabled }"
            @click="increment"
            type="button"
            :disabled="disabled"
            name="button"
            data-cy="increment"
        >
            <IconsPlus />
        </button>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';

const props = defineProps({
    id: {
        type: String,
        default: 'stepper',
    },
    min: {
        type: Number,
        default: 1,
    },
    max: {
        type: Number,
        default: 100,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Number,
        default: null,
    },
});
const emit = defineEmits(['update:modelValue', 'limitReached']);

const idPrefix = useId();

const internalValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        if (value) {
            emit('update:modelValue', value);
        }
    },
});

const disabledMinus = computed(() => {
    if (internalValue.value > props.min && props.disabled == false) {
        return false;
    } else {
        return true;
    }
});

function increment() {
    if (internalValue.value < props.max) {
        internalValue.value = internalValue.value + 1;
    } else {
        internalValue.value = props.max;
        emit('limitReached');
    }
}

function decrement() {
    if (internalValue.value > props.min && disabledMinus.value == false) {
        internalValue.value = internalValue.value - 1;
    }
}

function validate(value) {
    if (!value && value !== '0' && value !== 0) return;
    if (value > props.max) {
        internalValue.value = props.max;
        emit('limitReached');
        return;
    }

    if (value < props.min) {
        internalValue.value = props.min;
        return;
    }

    internalValue.value = value;
    emit('update:modelValue', internalValue.value);
}

function blur(e) {
    const v = e.target.value;
    if (isNaN(v) || v === '' || v === null || !v || v === '0') {
        e.target.value = internalValue.value;
    }
    validate();
}

function validateInput(event) {
    if (event.key.match(/[.,\-\+]/)) {
        event.preventDefault();
        return false;
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;
    border-radius: 25px;
    border: 1px solid $color-dark-disabled;
    box-sizing: border-box;

    transition: 0.3s ease;

    &:hover {
        border-color: #808080;
    }

    &:focus-within {
        border-color: $color-light;
    }

    input {
        background-color: unset;
        border-top: 0;
        border-bottom: 0;
        border-left: 1px solid $color-dark-disabled;
        border-right: 1px solid $color-dark-disabled;

        width: 100%;
        height: 34px;

        text-align: center;

        &:focus-visible {
            outline: unset;
        }

        /* remove input arrows */
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }

        &:disabled {
            cursor: not-allowed;
            color: $color-disabled;
        }
    }

    &__button {
        width: 58px;
        height: 100%;
        padding: unset;
        background-color: unset;
        border: 0;
        transition: 0.3s ease;

        :deep(svg) {
            fill: $color-light;
            width: 22px;
            height: 22px;
            margin: 9px 18px 9px 18px;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color-dark-02;
        }

        &:focus-visible {
            outline: unset;
        }

        &--disabled {
            cursor: not-allowed;

            :deep(svg) {
                fill: $color-disabled;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: unset;
            }
        }

        &--minus {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
        }

        &--plus {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
        }
    }

    &--small {
        height: 40px;
        width: 140px;
        border-radius: 20px;

        .stepper {
            &__button {
                width: 40px;

                :deep(svg) {
                    width: 18px;
                    height: 18px;
                    margin: 11px;
                }
            }
        }
    }

    &--disabled {
        cursor: not-allowed;

        &:hover {
            border-color: $color-dark-disabled;
        }

        &:focus-within {
            border-color: $color-dark-disabled;
        }
    }
}
</style>
